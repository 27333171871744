import React, { useState, useEffect } from 'react';
import MagazineDetailComponent from '../components/magazineDetail';
import axios from 'axios';
import { amplitudeLog } from '../Lib/ampletude';
import { getPlatform } from '../utils';
import { useSiteMetadata } from 'src/hooks/useSiteMetadata';
import { SEO } from 'src/components/seo';
import { PageProps } from 'gatsby';
import { convert } from 'html-to-text';

export default function Magazine(props: any) {
  const [recommendMagazines, setRecommendMagazines] = useState(null as any);
  const { apiUrl } = useSiteMetadata();

  useEffect(() => {
    axios
      .get(`${apiUrl}/v1/boards?category=tips&tag=&page=0&limit=4&order=random`)
      .then((response) => setRecommendMagazines(response.data.data))
      .catch((err) => {
        setRecommendMagazines([]);
      });
    amplitudeLog(`Magazine Detail View`, {
      id: props.pageContext.magazine.id,
      type: getPlatform(),
    });
  }, []);

  return (
    <MagazineDetailComponent
      magazine={props.pageContext.magazine}
      recommendMagazines={recommendMagazines}
      location={props.location}
    />
  );
}

// 매거진 상세 화면 Head
export const Head = (props: PageProps) => {
  const { data, location, pageContext, params, serverData, ...rest } = props;
  const { descriptionMagazines, siteUrl } = useSiteMetadata();
  const title = pageContext.magazine.title;
  const pageUrl = `${siteUrl}${location.pathname}`;
  const content = convert(pageContext.magazine.content, {
    selectors: [{ selector: 'img', format: 'skip' }],
  })
    .replaceAll(/[\t\n]/g, '')
    .substring(0, 151);
  const magazineDescription = content || `${pageContext.magazine.title} #${pageContext.magazine.tag}`;

  return (
    <SEO title={title} description={magazineDescription} pageUrl={pageUrl}>
      <meta id="og_title" name="og:title" property="og:title" content={title} />
    </SEO>
  );
};
